import { ChainId, Token } from '@pancakeswap/sdk'

export const optimismTokens = {
    weth: new Token(
        ChainId.OPTIMISTIC,
        '0x4200000000000000000000000000000000000006',
        18,
        'WETH',
        'WETH Token',
        '',
    ),
    usdt: new Token(
        ChainId.OPTIMISTIC,
        '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        18,
        'USDT',
        'USDT Token',
        '',
    ),
    usdc: new Token(
        ChainId.OPTIMISTIC,
        '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        18,
        'USDC',
        'USDC Token',
        '',
    ),
    op: new Token(
        ChainId.OPTIMISTIC,
        '0x4200000000000000000000000000000000000042',
        18,
        'OP',
        'Optimism Token',
        '',
    ),

}
