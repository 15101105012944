import { ChainId, Token } from '@pancakeswap/sdk'

export const baseMainnetToken = {
    cloud: new Token(
        ChainId.BASE_MAINNET,
        '0x17d8217c0F4b4742aaaCe053281f42EB05AB211D',
        18,
        'CLOUD',
        'CLOUD Token',
        '',
    ),
    cloudInvestor: new Token(
        ChainId.BASE_MAINNET,
        '0x187C155DBfD57a0EE63Be0a411533E2A1ed5E6c2',
        18,
        'CLOUD',
        'CLOUD Investor',
        '',
    ),
    weth: new Token(
        ChainId.BASE_MAINNET,
        '0x4200000000000000000000000000000000000006',
        18,
        'WETH',
        'WETH Token',
        '',
    ), 
    usdc: new Token(
        ChainId.BASE_MAINNET,
        '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
        6,
        'USDC',
        'USDC Token',
        '',
    ),
}
