import JSBI from 'jsbi'
import { Token } from './entities/token'

// exports for external consumption
export type BigintIsh = JSBI | number | string


export enum ChainId {
  ETHEREUM = 1,
  RINKEBY = 4,
  GOERLI = 5,
  BSC = 56,
  BSC_TESTNET = 97,
  ETHW_MAINNET = 10001,
  ONUS_TESTNET = 1945,
  ONUS = 1975,
  BASE_GOERLI = 84531,
  OPTIMISTIC = 10,
  BASE_MAINNET = 8453,
  OPBNB_MAINNET = 204,
  OPBNB_TESTNET = 5611
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const FACTORY_ADDRESS = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'

const FACTORY_ADDRESS_ETH = '0x1097053Fd2ea711dad45caCcc45EfF7548fCB362'

export const FACTORY_ADDRESS_MAP: Record<number, string> = {
  [ChainId.ETHEREUM]: FACTORY_ADDRESS_ETH,
  [ChainId.RINKEBY]: FACTORY_ADDRESS_ETH,
  [ChainId.GOERLI]: FACTORY_ADDRESS_ETH,
  [ChainId.BSC]: FACTORY_ADDRESS,
  [ChainId.BSC_TESTNET]: '0x6725f303b657a9451d8ba641348b6761a6cc7a17',
  [ChainId.ETHW_MAINNET]: "0x0939701b35962a3f03fd5db33ed3f27056b13bfd",
  [ChainId.ONUS_TESTNET]: "0x58907fDDc206Df64a4bf1a366Cc27b16569Ce301",
  [ChainId.ONUS]: "0xA5DA4dC244c7aD33a0D8a10Ed5d8cFf078E86Ef3",
  [ChainId.BASE_GOERLI]: "0x703EB7F1b24Ed0801E3B13d09932597b423Ac040",
  [ChainId.OPTIMISTIC]: "0x25CbdDb98b35ab1FF77413456B31EC81A6B6B746",
  [ChainId.BASE_MAINNET]: "0x0dcCfdF227c3f7b1E5d34B2488A7B0ca01d400a4",
  [ChainId.OPBNB_MAINNET]: "",
  [ChainId.OPBNB_TESTNET]: ""

}
export const INIT_CODE_HASH = '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5'

const INIT_CODE_HASH_ETH = '0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d'
export const INIT_CODE_HASH_MAP: Record<number, string> = {
  [ChainId.ETHEREUM]: INIT_CODE_HASH_ETH,
  [ChainId.RINKEBY]: INIT_CODE_HASH_ETH,
  [ChainId.GOERLI]: INIT_CODE_HASH_ETH,
  [ChainId.BSC]: INIT_CODE_HASH,
  [ChainId.BSC_TESTNET]: '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66',
  [ChainId.ETHW_MAINNET]: "0xced7c507bf75a9c4a42a9c14d582db9f48b2de7a90ccc86d338a41f541fe4f53",
  [ChainId.ONUS_TESTNET]: "0xced7c507bf75a9c4a42a9c14d582db9f48b2de7a90ccc86d338a41f541fe4f53",
  [ChainId.ONUS]: "0x5393060e4ffa74ba02e5aaed6c5ccdba4186aa7759b8cf1fa32810a204843ac4",
  [ChainId.BASE_GOERLI]: "0x7a1d6bc7df460b200a3b4ec03571f2d70efa35a1d13f3f117be42c20617cebb1",
  [ChainId.OPTIMISTIC]: "0xc1ac28b1c4ebe53c0cff67bab5878c4eb68759bb1e9f73977cd266b247d149f0",
  [ChainId.BASE_MAINNET]: "0x7a1d6bc7df460b200a3b4ec03571f2d70efa35a1d13f3f117be42c20617cebb1",
  [ChainId.OPBNB_MAINNET]: "",
  [ChainId.OPBNB_TESTNET]: ""

}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _9975 = JSBI.BigInt(9975)
export const _10000 = JSBI.BigInt(10000)

export const MaxUint256 = JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}

export const WETH9 = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.GOERLI]: new Token(
    ChainId.GOERLI,
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.BASE_GOERLI]: new Token(
    ChainId.BASE_GOERLI,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
    ),
    [ChainId.BASE_MAINNET]: new Token(
        ChainId.BASE_MAINNET,
        '0x4200000000000000000000000000000000000006',
        18,
        'WETH',
        'Wrapped Ether',
        'https://weth.io'
      ),
  [ChainId.OPTIMISTIC]: new Token(
    ChainId.OPTIMISTIC,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.OPBNB_MAINNET]: new Token(
    ChainId.OPBNB_MAINNET,
    '0x4200000000000000000000000000000000000006',
    18,
    'WBNB',
    'Wrapped BNB',
    ''
  ),
  [ChainId.OPBNB_TESTNET]: new Token(
    ChainId.OPBNB_TESTNET,
    '0x617d91847b74B70a3D3e3745445cb0d1b3c8560E',
    18,
    'WBNB',
    'Wrapped opBNB',
    ''
  )

}

export const WBNB = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0x418D75f65a02b3D53B2418FB8E1fe493759c7605',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.ETHW_MAINNET]: new Token(
    ChainId.ETHW_MAINNET,
    '0xaA7427D8f17D87a28F5e1ba3aDBB270bAdbe1011',
    18,
    'ETHW',
    'Wrapped ETHW',
    ''
  ),
  [ChainId.ONUS_TESTNET]: new Token(
    ChainId.ONUS_TESTNET,
    '0x5653a147156B1A1e0E09661e4841B2fFFb1cd438',
    18,
    'WONUS',
    'Wrapped ONUS',
    'https://www.binance.org'
  ),
  [ChainId.ONUS]: new Token(
    ChainId.ONUS,
    '0x4c761E48d1E735af551cc38ABCBDCe1d7FaaC6E4',
    18,
    'WONUS',
    'Wrapped ONUS',
    'https://rpc.onuschain.io'
  ),
  [ChainId.OPBNB_MAINNET]: new Token(
    ChainId.OPBNB_MAINNET,
    '0x4200000000000000000000000000000000000006',
    18,
    'WBNB',
    'Wrapped opBNB',
    ''
  ),
  [ChainId.OPBNB_TESTNET]: new Token(
    ChainId.OPBNB_TESTNET,
    '0x617d91847b74B70a3D3e3745445cb0d1b3c8560E',
    18,
    'WBNB',
    'Wrapped opBNB',
    ''
  )

}

export const WNATIVE: Record<number, Token> = {
  [ChainId.ETHEREUM]: WETH9[ChainId.ETHEREUM],
  [ChainId.RINKEBY]: WETH9[ChainId.RINKEBY],
  [ChainId.GOERLI]: WETH9[ChainId.GOERLI],
  [ChainId.BSC]: WBNB[ChainId.BSC],
  [ChainId.BSC_TESTNET]: WBNB[ChainId.BSC_TESTNET],
  [ChainId.ETHW_MAINNET]: WBNB[ChainId.ETHW_MAINNET],
  [ChainId.ONUS_TESTNET]: WBNB[ChainId.ONUS_TESTNET],
  [ChainId.ONUS]: WBNB[ChainId.ONUS],
  [ChainId.BASE_GOERLI]: WETH9[ChainId.BASE_GOERLI],
  [ChainId.BASE_MAINNET]: WETH9[ChainId.BASE_MAINNET],
  [ChainId.OPTIMISTIC]: WETH9[ChainId.OPTIMISTIC],
  [ChainId.OPBNB_MAINNET]: WETH9[ChainId.OPBNB_MAINNET],
  [ChainId.OPBNB_TESTNET]: WETH9[ChainId.OPBNB_TESTNET]

}

export const NATIVE: Record<
  number,
  {
    name: string
    symbol: string
    decimals: number
  }
> = {
  [ChainId.ETHEREUM]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.RINKEBY]: { name: 'Rinkeby Ether', symbol: 'RIN', decimals: 18 },
  [ChainId.GOERLI]: { name: 'Goerli Ether', symbol: 'GOR', decimals: 18 },
  [ChainId.BSC]: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  [ChainId.BSC_TESTNET]: {
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
    decimals: 18,
  },
  [ChainId.ETHW_MAINNET]: {
    name: 'ETHW Chain Native Token',
    symbol: 'ETHW',
    decimals: 18,
  },
  [ChainId.ONUS_TESTNET]: {
    name: 'ONUS Chain Native Token',
    symbol: 'ONUS',
    decimals: 18,
  },
  [ChainId.ONUS]: {
    name: 'ONUS Chain Native Token',
    symbol: 'ONUS',
    decimals: 18,
  },
  // [ChainId.BASE_GOERLI]: {
  //   name: 'BASE GOERLI Chain Native Token',
  //   symbol: 'ETH',
  //   decimals: 18,
  //   },
  [ChainId.BASE_MAINNET]: {
      name: 'Base Mainnet',
      symbol: 'ETH',
      decimals: 18,
    },
  [ChainId.OPTIMISTIC]: {
    name: 'OPTIMISTIC Chain Native Token',
    symbol: 'ETH',
    decimals: 18,
  },
  [ChainId.OPBNB_MAINNET]: {
    name: 'opBNB Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  [ChainId.OPBNB_TESTNET]: {
    name: 'opBNB Testnet Native Token',
    symbol: 'tBNB',
    decimals: 18,
  },

}
