import throttle from "lodash/throttle";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box } from "../../components/Box";
import { useMatchBreakpoints } from "../../contexts";
import GetWindowSize from "../../util/getWindowSize";
import Footer from "./components/Footer/footer";
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import { ItemsMenu } from "./items-menu";
import { LogoIcon } from "../../components/Svg";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  overflow: hidden;
`;

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
    linkComponent = "a",
    banner,
    rightSide,
    isDark,
    toggleTheme,
    currentLang,
    setLang,
    cakePriceUsd,
    links,
    subLinks,
    footerLinks,
    activeItem,
    activeSubItem,
    langs,
    buyCakeLabel,
    children,
}) => {
    const { isMobile, isMd } = useMatchBreakpoints();
    const [showMenu, setShowMenu] = useState(true);
    const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);
    const windowSize = GetWindowSize();
    const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

    const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

    useEffect(() => {
        const handleScroll = () => {
            const currentOffset = window.pageYOffset;
            const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
            const isTopOfPage = currentOffset === 0;
            // Always show the menu when user reach the top
            if (isTopOfPage) {
                setShowMenu(true);
            }
            // Avoid triggering anything at the bottom because of layout shift
            else if (!isBottomOfPage) {
                if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
                    // Has scroll up
                    setShowMenu(true);
                } else {
                    // Has scroll down
                    setShowMenu(false);
                }
            }
            refPrevOffset.current = currentOffset;
        };
        const throttledHandleScroll = throttle(handleScroll, 200);

        window.addEventListener("scroll", throttledHandleScroll);
        return () => {
            window.removeEventListener("scroll", throttledHandleScroll);
        };
    }, [totalTopMenuHeight]);

    return (
        <MenuContext.Provider value={{ linkComponent }}>
            <Wrapper>
                <div className="w-full flex items-center justify-center">
                    <div className="xl:max-w-[1320px] w-full">
                        <div className="w-full lg:px-[16px] px-[16px] py-7 lg:py-5 flex items-center justify-between z-10 bg-[#141414] sticky top-0">
                            <div className="absolute inset-x-0 bottom-0 h-[0.5px] bg-gradient-to-r from-transparent via-[#EFB90B] to-transparent" />
                            <div>
                                <Link href="/">
                                    <a>
                                        <LogoIcon width={120} />
                                    </a>
                                </Link>
                            </div>
                            {/* <ItemsMenu /> */}
                            <div className="space-x-4 hidden">{rightSide}</div>
                        </div>
                    </div>
                </div>
                <BodyWrapper mt={!subLinks ? `0` : "0"}>
                    <Inner isPushed={false} showMenu={showMenu}>
                        {children}
                        <Footer />
                    </Inner>
                </BodyWrapper>
            </Wrapper>
        </MenuContext.Provider>
    );
};

export default Menu;
