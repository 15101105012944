import { ContextApi } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
    EarnFilledIcon,
    EarnFillIcon,
    InfoMenuFillIcon, InfoMenuIcon,
    MenuItemsType, MoreIcon, NftFilledIcon, NftFillIcon, TradeFilledIcon, TradeIcon
} from '@pancakeswap/uikit'
import { DropdownMenuItems, DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_CHAIN_CLOUDBASE, SUPPORT_CHAIN_OP } from 'utils/wagmi'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        [
            {
                label: t('Launchpad'),
                icon: TradeIcon,
                fillIcon: TradeFilledIcon,
                href: '/create-launchpad',
                items: [
                    {
                        label: t('create-launchpad'),
                        href: '/create-launchpad',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE,
                        status: { text: t('Beta'), color: 'success' },
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('List'),
                icon: NftFillIcon,
                fillIcon: NftFilledIcon,
                href: '/',
                showItemsOnMobile: false,
                items: [
                    {
                        label: t('List'),
                        href: '/',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE,
                        status: { text: t('Beta'), color: 'success' },
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
        ].map((item) => addMenuItemSupported(item, chainId))

export default config
