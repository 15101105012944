import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import {
  Box,
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount, useBalance } from 'wagmi'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { formatBigNumber } from 'utils/formatBalance'
import WalletUserMenuItem from './WalletUserMenuItem'
import WalletModal, { WalletView } from './WalletModal'

const UserMenu = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const avatarSrc = undefined
  const native = useNativeCurrency()
  const nativeBalance = useBalance({ address: account, enabled: !false })
  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        {/* <UserMenuItem as="button" disabled={isWrongNetwork} onClick={() => handleClick(`/my-project`)}>
          {t('My LaunchPad')}
        </UserMenuItem> */}
        {/* <UserMenuDivider />
        <NextLink href={`/profile/${account?.toLowerCase()}`} passHref>
          <UserMenuItem as="a" disabled={isWrongNetwork || chainId !== ChainId.ETHEREUMPOW}>
            {t('Your NFTs')}
          </UserMenuItem>
        </NextLink>
        <ProfileUserMenuItem
          isLoading={isLoading}
          hasProfile={hasProfile}
          disabled={isWrongNetwork || chainId !== ChainId.ETHEREUMPOW}
        /> */}
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu
        account={account}
        avatarSrc={avatarSrc}
        text={userMenuText}
        variant={userMenuVariable}
        isShowIcon={false}
        symbolNative={native?.symbol}
        nativeBalance={(!nativeBalance?.isFetched || nativeBalance.data === undefined) ? "0" : formatBigNumber(nativeBalance.data.value, 2)}
      >
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <CsConnectWalletButton scale="sm">
      <Box display='block'>
        <Trans>Connect Wallet</Trans>
      </Box>
    </CsConnectWalletButton>
  )
}

export default UserMenu

const CsConnectWalletButton = styled(ConnectWalletButton)`
  height: 46px;
  background: transparent;
  color:${({ theme }) => theme.colors.backgroundAlt};
  border:2px solid ${({ theme }) => theme.colors.cardBorder};
 
  @media screen and (max-width: 768px){
    height: 36px;
    font-size: 10px;
    padding:8px;
    margin:0
  }
`
