import {
    Cake,
    CakeFlexibleSideVaultV2,
    CakeVaultV2,
    Erc20,
    Erc20Bytes32, Multicall,
    Weth,
    Zap
} from 'config/abi/types'
import zapAbi from 'config/abi/zap.json'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useProviderOrSigner } from 'hooks/useProviderOrSigner'
import { useMemo } from 'react'
import { getMulticallAddress, getZapAddress } from 'utils/addressHelpers'
import {
    getAnniversaryAchievementContract,
    getBCakeFarmBoosterContract,
    getBCakeFarmBoosterProxyFactoryContract,
    getBCakeProxyContract,
    getBep20Contract,
    getCakeContract,
    getCakeFlexibleSideVaultV2Contract, getCakeVaultV2Contract, getCrossFarmingProxyContract,
    getMasterchefContract, getNonBscVaultContract, getSouschefContract
} from 'utils/contractHelpers'
import { useSigner } from 'wagmi'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { WNATIVE } from '@pancakeswap/sdk'
import registerAbi from "config/abi/registerAbi.json"
import wolfLabIdoAbi from 'config/abi/wolfLabIdoAbi.json';
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import IPancakePairABI from '../config/abi/IPancakePair.json'
import multiCallAbi from '../config/abi/Multicall.json'
import WETH_ABI from '../config/abi/weth.json'
import poolProposalsAbi from "../config/abi/poolProposals.json";
import poolStoreAbi from "../config/abi/poolStore.json";
import RunMarketplaceAbi from '../config/abi/RunMarketplaceAbi.json'
import RunBoxNftAbi from "../config/abi/RunTogetherBox.json"
import withdrawBoxAbi from "../config/abi/withdrawAbi.json"
import { getContract } from '../utils'
import { IPancakePair } from '../config/abi/types/IPancakePair'
import poolRunAbi from "../config/abi/poolRunAbi.json"
import { VaultKey } from '../state/types'
import { useActiveChainId } from './useActiveChainId'
import votingProposalsAbi from "../config/abi/votingProposals.json"
import dragonNftAbi from "../config/abi/dragonNft.json"
import dragonPoolAbi from "../config/abi/dragonNftPool.json"
import cloudFusionAbi from "../config/abi/cloudFusionAbi.json"
import tokenForCloud from "../config/abi/tokenForCloudAbi.json"
import nftForCloud from "../config/abi/nftForCloudAbi.json"
import cloudianAbi from "../config/abi/cloudianAbi.json"
import cloudAbi from "../config/abi/cloudAbi.json"
import stakeNftLaunchpadAbi from "../config/abi/stakeNftLaunchpad.json"
import stakeTokenLaunchpadAbi from "../config/abi/stakeTokenLaunchpad.json"
import lauchpadAbi from "../config/abi/lauchpadAbi.json"
import corePresaleAbi from "../config/abi/corePresaleAbi.json"
import battleContractAbi from '../config/abi/BattleContract.json'
import airdropAbi from "../config/abi/airdropAbi.json"
import launchpadFactoryABI from "../config/abi/launchpadFactoryABI.json"
import tokenFactoryABI from "../config/abi/tokenFactoryABI.json"
import launchpadAbi from "../config/abi/launchpad.json"
import lockLPABI from "../config/abi/lockLPABI.json"
/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string, withSignerIfPossible = true) => {
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(() => getBep20Contract(address, providerOrSigner), [address, providerOrSigner])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useCake = (): { reader: Cake; signer: Cake } => {
    const providerOrSigner = useProviderOrSigner()
    return useMemo(
        () => ({
            reader: getCakeContract(null),
            signer: getCakeContract(providerOrSigner),
        }),
        [providerOrSigner],
    )
}

export const useMasterchef = (withSignerIfPossible = true) => {
    const { chainId } = useActiveChainId()
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(() => getMasterchefContract(providerOrSigner, chainId), [providerOrSigner, chainId])
}

export const useSousChef = (id) => {
    const { data: signer } = useSigner()
    return useMemo(() => getSouschefContract(id, signer), [id, signer])
}

export const useVaultPoolContract = (vaultKey: VaultKey): CakeVaultV2 | CakeFlexibleSideVaultV2 => {
    const { data: signer } = useSigner()
    return useMemo(() => {
        if (vaultKey === VaultKey.CakeVault) {
            return getCakeVaultV2Contract(signer)
        }
        if (vaultKey === VaultKey.CakeFlexibleSideVault) {
            return getCakeFlexibleSideVaultV2Contract(signer)
        }
        return null
    }, [signer, vaultKey])
}

export const useAnniversaryAchievementContract = (withSignerIfPossible = true) => {
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(() => getAnniversaryAchievementContract(providerOrSigner), [providerOrSigner])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
export function useContract<T extends Contract = Contract>(
    address: string | undefined,
    ABI: any,
    withSignerIfPossible = true,
): T | null {
    const { provider } = useActiveWeb3React()

    const providerOrSigner = useProviderOrSigner(withSignerIfPossible) ?? provider

    const canReturnContract = useMemo(() => address && ABI && providerOrSigner, [address, ABI, providerOrSigner])

    return useMemo(() => {
        if (!canReturnContract) return null
        try {
            return getContract(address, ABI, providerOrSigner)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, providerOrSigner, canReturnContract]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
    return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWNativeContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    return useContract<Weth>(chainId ? WNATIVE[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract<Erc20Bytes32>(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): IPancakePair | null {
    return useContract(pairAddress, IPancakePairABI, withSignerIfPossible)
}

export function useMulticallContract() {
    const { chainId } = useActiveWeb3React()
    return useContract<Multicall>(getMulticallAddress(chainId), multiCallAbi, false)
}

export function useZapContract(withSignerIfPossible = true) {
    return useContract<Zap>(getZapAddress(), zapAbi, withSignerIfPossible)
}

export function useBCakeFarmBoosterContract(withSignerIfPossible = true) {
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(() => getBCakeFarmBoosterContract(providerOrSigner), [providerOrSigner])
}

export function useBCakeFarmBoosterProxyFactoryContract(withSignerIfPossible = true) {
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(() => getBCakeFarmBoosterProxyFactoryContract(providerOrSigner), [providerOrSigner])
}

export function useBCakeProxyContract(proxyContractAddress: string, withSignerIfPossible = true) {
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(
        () => proxyContractAddress && getBCakeProxyContract(proxyContractAddress, providerOrSigner),
        [providerOrSigner, proxyContractAddress],
    )
}

export const useNonBscVault = (withSignerIfPossible = true) => {
    const { chainId } = useActiveChainId()
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(() => getNonBscVaultContract(providerOrSigner, chainId), [providerOrSigner, chainId])
}

export const useCrossFarmingProxy = (proxyContractAddress: string, withSignerIfPossible = true) => {
    const { chainId } = useActiveChainId()
    const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
    return useMemo(
        () => proxyContractAddress && getCrossFarmingProxyContract(proxyContractAddress, providerOrSigner, chainId),
        [proxyContractAddress, providerOrSigner, chainId],
    )
}

export function useDemoUseContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
export function useVotingContract(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, votingProposalsAbi, withSignerIfPossible)
}

export function useRunMarketplaceContract(runMarketplaceAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(runMarketplaceAddress, RunMarketplaceAbi, withSignerIfPossible)
}

export function useRunBoxNft(RunBoxNftAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(RunBoxNftAddress, RunBoxNftAbi, withSignerIfPossible)
}

export function useMysteryBoxContract(RunBoxNftAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(RunBoxNftAddress, RunBoxNftAbi, withSignerIfPossible)
}

export function useDragonNft(NftAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(NftAddress, dragonNftAbi, withSignerIfPossible)
}

export function useDragonNftPool(PoolAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(PoolAddress, dragonPoolAbi, withSignerIfPossible)
}

export function useCloudFusion(FusionAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(FusionAddress, cloudFusionAbi, withSignerIfPossible)
}

export function usePoolRun(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, poolRunAbi, withSignerIfPossible)
}

export function useWithdrawNftRun(contractWithdraw?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractWithdraw, withdrawBoxAbi, withSignerIfPossible)
}

export function usePoolProposalsContract(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, poolProposalsAbi, withSignerIfPossible)
}

export function usePoolStoreContract(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, poolStoreAbi, withSignerIfPossible)
}

export function useCakeVaultContract(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, poolStoreAbi, withSignerIfPossible)
}

export function useTokenForCloud(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, tokenForCloud, withSignerIfPossible)
}

export function useNFTForCloud(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, nftForCloud, withSignerIfPossible)
}

export function useCloudiAn(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, cloudianAbi, withSignerIfPossible)
}

export function useCloud(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, cloudAbi, withSignerIfPossible)
}

export function useERC1155Contract(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, nftForCloud, withSignerIfPossible)
}

export function useContractStakeNftLaunchpad(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, stakeNftLaunchpadAbi, withSignerIfPossible)
}

export function useContractStakeToken(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, registerAbi, withSignerIfPossible)
}

export function useCsERC20(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, ERC20_ABI, withSignerIfPossible)
}

export function useLaunchpad(contractAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAddress, lauchpadAbi, withSignerIfPossible)
}

export function useCorePresale(presaleAdress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(presaleAdress, corePresaleAbi, withSignerIfPossible)
}
export function useCorePreSaleWolfLab(preSaleAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(preSaleAddress, wolfLabIdoAbi, withSignerIfPossible)
}

export function useCloudManBattle(BattleContract?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(BattleContract, battleContractAbi, withSignerIfPossible)
}

export function useAirdrop(contractAirdrop?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAirdrop, airdropAbi, withSignerIfPossible)
}

export function useLaunchpadFactory(contractAirdrop?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAirdrop, launchpadFactoryABI, withSignerIfPossible)
}
export function useTokenFactory(contractAirdrop?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAirdrop, tokenFactoryABI, withSignerIfPossible)
}

export function usePurchase(contractAirdrop?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAirdrop, launchpadAbi, withSignerIfPossible)
}

export function useLockLP(contractAirdrop?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(contractAirdrop, lockLPABI, withSignerIfPossible)
}