import { ChainId, Token } from '@pancakeswap/sdk'

export const opBNBMainnet = {
    usdt: new Token(
        ChainId.OPBNB_MAINNET,
        '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
        18,
        'USDT',
        'USDT Token',
        'https://tether.to/',
    ),
}

