import { ChainId, Token } from '@pancakeswap/sdk'

export const opBNBTestnet = {
    usdt: new Token(
        ChainId.OPBNB_TESTNET,
        '0x730484C237820398DAAB7aC2c2Bf26152Ff11ab8',
        18,
        'USDT',
        'USDT Token',
        'https://tether.to/',
    ),
}

