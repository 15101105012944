import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "./Button";
import { BaseButtonProps } from "./types";

const IconButton: PolymorphicComponent<BaseButtonProps, "button"> = styled(Button)<BaseButtonProps>`
    padding: 0;
    border-radius:50%;
    width: ${({ scale }) => (scale === "sm" ? "36px" : "42px")};
    height: ${({ scale }) => (scale === "sm" ? "36px" : "42px")};
`;

export default IconButton;
