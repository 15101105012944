import Link from "next/link";
import styled from "styled-components"

export const BuyCloud = () => {
    return (
        <CsBuyCloud>
            <a href="https://cloudbase.finance/swap" target="_blank">
                Buy Cloud
            </a>
        </CsBuyCloud>
    )
};

const CsBuyCloud = styled.div`
    margin-right: 10px;
    border-radius: 28px;
    height: 46px;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    padding: 11px 26px;
    font-style: normal;
    font-weight: 600;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(2px);
    @media screen and (max-width: 768px){
    height: 36px;
    font-size: 10px;
    padding:8px;
    /* margin:0 */
  }
`
