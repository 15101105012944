import { baseMainnetToken } from '@pancakeswap/tokens'
import { PoolCategory, SerializedConfigPool } from './types'

const pools: SerializedConfigPool[] = [
  {
    sousId: 1,
    stakingToken: baseMainnetToken.cloud,
    earningToken: baseMainnetToken.cloud,
    contractAddress: {
      97: '',
      56: '0x0ef5F58496Ccc35Ff906EF6413cF1c2864Cae25F',
      84531: "0x3b28a70a60253deC8056b6f4328E9B630dB09181",
      8453: "0x0ef5F58496Ccc35Ff906EF6413cF1c2864Cae25F"
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '200000000000000000',
    decimals: 18,
  }
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default pools