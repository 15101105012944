import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'
import Cookies from 'js-cookie'

export const BSC_BLOCK_TIME = 3
export const BASE_BSC_SCAN_URLS = {
    [ChainId.BSC]: 'https://bscscan.com',
    [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
    [ChainId.BASE_GOERLI]: 'https://goerli.basescan.org/'
}
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 1
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR

export const CAKE_PER_BLOCKV2 = new BigNumber(0.07)
export const BLOCKS_PER_YEARV2 = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2.times(BLOCKS_PER_YEARV2)

export const BASE_URL = 'https://board.runtogether.net'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const startTimeStake = 1662469200000
export const LIMIT_VOTING = 100
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const AMOUNT_FULL_POOL = "2500000"
export const DEFAULT_NFT_ID = 0
export const MAX_UINT_256 = "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
export const ITEMSPERPAGE = 10
export const MIN_PERCENT_REFUND = 0.01
export const WEB3_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDQzZDVCMzgwM2JiZGRCNTg4YjIzYzJBYzQ5ODlDOTAxMmZkMUIyMTMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2OTg4OTY4MjQ4ODksIm5hbWUiOiJDbG91ZEJhZSJ9.9mZgQD7gxIg3OJUCdijY-eB3wWwYpBvo2ZCqtBztXWM"

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const BASE_BSC_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const URL = process.env.NEXT_PUBLIC_API
export const TRANDING_FEE = 3
export const TRANSFER_FEE = 5
export const DEFAULT_INPUT_AMOUNT = "1"
export const SWAP_RATE_PRE_SALE = 123200
export const SWAP_RATE_PRE_SALE_WOLFLAB = 664100
export const CHECK_IMG_UPLOAD = ["image/png", "image/jpg", "image/jpeg", "image/gif"]
export const KEY_LOGIN = "access_token"
export const ITEM_PER_PAGE = 3

export const chaindStartTimestamp = {
    [ChainId.BASE_GOERLI]: 1688157126,
}

export const STATUS = {
    UPCOMING: "upcoming",
    LIVE: "live",
    END: "end",
    CANCELED: "canceled",
    ALL: "all",
    FILLED: 'filled'
}

export const ModalTypeEnum = {
    "NULL": "NULL",
    "MODAL_CREATE_TOKEN": "MODAL_CREATE_TOKEN",
    "MODAL_UPDATE_TIME_POOL": "MODAL_UPDATE_TIME_POOL",
    "MODAL_TRANSFER_OWNERSHIP": "MODAL_TRANSFER_OWNERSHIP",
    "MODAL_AFFILIATE_PROGRAM": "MODAL_AFFILIATE_PROGRAM",
    "MODAL_CONTRIBUTORS": "MODAL_CONTRIBUTORS",
    "MODAL_UPDATE_WHITELIST": "MODAL_UPDATE_WHITELIST",
}

export const formatNumberPayment = (price: number, minimum = 4, maximum = 4) => {
    if (!price) return 0;
    return Number(new BigNumber(price).decimalPlaces(5, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: minimum, maximumFractionDigits: maximum }).replace(/\.?0+$/, '')
}

export const setCookiesToken = (token: string) => Cookies.set(KEY_LOGIN, token);
export const getCookiesToken = () => Cookies.get(KEY_LOGIN);
export const removeCookiesToken = () => Cookies.remove(KEY_LOGIN);