import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
        <path d="M11.7197 22.75H7.21973C3.06973 22.75 1.21973 20.9 1.21973 16.75V12.25C1.21973 8.1 3.06973 6.25 7.21973 6.25H11.7197C15.8697 6.25 17.7197 8.1 17.7197 12.25V16.75C17.7197 20.9 15.8697 22.75 11.7197 22.75ZM7.21973 7.75C3.89973 7.75 2.71973 8.93 2.71973 12.25V16.75C2.71973 20.07 3.89973 21.25 7.21973 21.25H11.7197C15.0397 21.25 16.2197 20.07 16.2197 16.75V12.25C16.2197 8.93 15.0397 7.75 11.7197 7.75H7.21973Z"/>
        <path d="M18.1197 13.75H16.9697C16.5597 13.75 16.2197 13.41 16.2197 13V12.25C16.2197 8.93 15.0397 7.75 11.7197 7.75H10.9697C10.5597 7.75 10.2197 7.41 10.2197 7V5.85C10.2197 2.67 11.6397 1.25 14.8197 1.25H18.1197C21.2997 1.25 22.7197 2.67 22.7197 5.85V9.15C22.7197 12.33 21.2997 13.75 18.1197 13.75ZM17.7197 12.25H18.1197C20.4697 12.25 21.2197 11.5 21.2197 9.15V5.85C21.2197 3.5 20.4697 2.75 18.1197 2.75H14.8197C12.4697 2.75 11.7197 3.5 11.7197 5.85V6.25C15.8697 6.25 17.7197 8.1 17.7197 12.25Z"/>
    </Svg>
  );
};
export default Icon;