import { baseMainnetToken } from '@pancakeswap/tokens'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
    {
        pid: null,
        lpSymbol: 'ETH-CLOUD LP',
        lpAddress: '0x17d8217c0F4b4742aaaCe053281f42EB05AB211D',
        token: baseMainnetToken.weth,
        quoteToken: baseMainnetToken.cloud,
        boosted: false
    }
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: p.lpAddress,
}))

export default priceHelperLps
