import { FixedNumber } from '@ethersproject/bignumber'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const FIXED_TEN_IN_POWER_18 = FixedNumber.from(getFullDecimalMultiplier(18))

export const masterChefAddresses = {
  97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  56: '0x827d6eb00efb26a4d815706771dfde2abc84c451',
  1945: '0xfC6412C60db06DE222538d51f0585933153dB9a5',
  1975: '0xe5485c394D8d1Ce80d0D648a4c6DC9c749dda7cb' ,
  84531: '0x0c6F2bCD7d53829afa422b4535c8892B1566E8c5',
  8453: '0x12cd60d2aBDa4E032fF41Ce6Db75AEd39D848C74',
}

export const nonBSCVaultAddresses = {
  1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
  5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
}
