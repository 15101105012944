import { baseTestnetTokens } from '@pancakeswap/tokens'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
    {
        pid: null,
        lpSymbol: 'ETH-CLOUD LP',
        lpAddress: '0x377208A4697BaFb15438611D87617a5590E83817',
        token: baseTestnetTokens.weth,
        quoteToken: baseTestnetTokens.cloud,
        boosted: false
    }
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: p.lpAddress,
}))

export default priceHelperLps
