import { Flex } from "../../../../components/Box";
import { Container, ContainerContent, ContainerFooter, ContainerMask } from "./styled";
import { PiTelegramLogoFill } from "react-icons/pi";
import { FaDiscord } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Text } from "../../../../components/Text";

export const Footer = () => {
    const handleClick = (url: string) => {
        window.open(url)
    }
  return (
    <ContainerFooter>
      <Container>
        <ContainerContent>
            {/* <Flex alignItems="center" style={{gap:"24px"}}>
                <PiTelegramLogoFill size={24} color="white" style={{cursor:"pointer"}} />
                <FaDiscord size={24} color="white" style={{cursor:"pointer"}} />
                <FaGithub size={24} color="white" style={{cursor:"pointer"}} />
                <FaTwitter size={24} color="white" style={{cursor:"pointer"}} />
                <FaYoutube size={24} color="white" style={{cursor:"pointer"}} />
            </Flex> */}
            <Text color="disable">Copyright© Althena 2024.</Text>
        </ContainerContent>
      </Container>
      <ContainerMask/>
    </ContainerFooter>
  );
};

export default Footer;
