import { useTranslation } from '@pancakeswap/localization'
import { Button, Logo, Menu as UikitMenu } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useRouter } from 'next/router'
import { createContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { BuyCloud } from './BuyCloud'
import { BsThreeDotsVertical } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"
import { Drawer } from 'antd'
import Link from 'next/link'
import clsx from 'clsx'



const RefreshCreate = []

export const RefreshCreateGlobal = createContext(RefreshCreate)

const Menu = (props) => {
    const { t } = useTranslation()
    const { pathname, push } = useRouter()
    const [open, setOpen] = useState(false);

    const menuItems = useMenuItems()

    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
    const getFooterLinks = useMemo(() => {
        return footerLinks(t)
    }, [t])
    return (
        <>
            <UikitMenu
                linkComponent={(linkProps) => {
                    return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
                }}
                rightSide={
                    <>
                        <BuyCloud />
                        <NetworkSwitcher />
                        <UserMenu />
                        {/* <Auth/> */}
                        <>

                            <div className='lg:hidden block'>
                                <div
                                    onClick={() => setOpen(true)}
                                    style={{
                                        border: "1px solid white"
                                    }}
                                    className='w-10 h-10 flex items-center justify-center ml-4 rounded-full cursor-pointer bg-[#ffffff80]'
                                >
                                    <BsThreeDotsVertical />
                                </div>
                            </div>
                            <CsDrawer
                                title=""
                                placement="right"
                                open={open}
                                headerStyle={{
                                    display: "none"
                                }}
                                width="100%"
                                style={{
                                    backgroundColor: "transparent",
                                }}
                            >
                                <div className='flex justify-between items-center p-5'>
                                    <Logo href="/" isDark={false} />
                                    <div
                                        onClick={() => setOpen(false)}
                                        style={{
                                            border: "1px solid white"
                                        }}
                                        className='w-10 h-10 flex items-center justify-center ml-4 rounded-full cursor-pointer bg-[#ffffff80]'
                                    >
                                        <AiOutlineClose />
                                    </div>
                                </div>
                                {
                                    menuItems.map((item) => (
                                        <p
                                            onClick={() => {
                                                setOpen(false);
                                                push(item.href)
                                            }}
                                            className={clsx("p-5 text-white font-semibold text-lg cursor-pointer", pathname === item.href && "bg-[#ffffff1a]")}
                                        >
                                            {item.label}
                                        </p>
                                    ))
                                }
                            </CsDrawer>
                        </>
                    </>
                }
                links={menuItems}
                footerLinks={getFooterLinks}
                activeItem={activeMenuItem?.href}
                activeSubItem={activeSubMenuItem?.href}
                {...props}
            />
        </>
    )
}

export default Menu

const CsDrawer = styled(Drawer)`
    background-image: url("/images/base_img/blur.png") !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-origin: content-box !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    .ant-drawer-body{
        padding: 0;
    }
`