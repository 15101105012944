import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'
declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Inter';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
    body {
        background: #141414;
    }
    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.input};
        border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
        border-radius: 10px;
    }
    .global-border-white{
        border: 1px solid #EFEFEF;
    }
    .ant-select-selection-item{
        font-weight: 600;
    }
    .ant-select-item-option-selected .ant-select-item-option-content{
        color: #1A9BFD;
    }
    .ant-form-item-label label{
        color: #718096 !important;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600 !important;
        letter-spacing: 0.2px;
    }
    .ant-form-item-required{
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        color: #718096 !important;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600 !important;
        letter-spacing: 0.2px;
        &::after{
            display: none;
        }
        &::before{
            color: #718096 !important;
            margin-left: 10px;
        }
    }
    button.ant-btn-primary{
        background-color: #1A9BFD !important;
    }
    .ant-remove-ok .ant-picker-ranges{
        display: none !important;
    }
    .img-crop-control{
        display: none !important;
    }
    @media screen and (max-width: 450px) {
        .ant-picker-datetime-panel{
            display: grid !important;
        }
    }
    .ant-select-selection-item{
        font-weight: 600 !important;
    }
    .ant-checkbox{
        align-self: flex-start;
    }
`

export default GlobalStyle
