import type { ThemeConfig } from 'antd';

export const themeAntd: ThemeConfig = {
    components: {
        Radio: {
            radioSize: 20
        },
        Checkbox: {
            controlInteractiveSize: 20
        },
    },
};