import { ChainId, JSBI, Percent, Token, WNATIVE } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { bscTokens, bscTestnetTokens, USDC, USDT, BUSD, WBTC_ETH, ethwTokens, onusTestnetTokens, onusMainnetTokens, baseTestnetTokens, optimismTokens, baseMainnetToken, opBNBMainnet, opBNBTestnet  } from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {
    [ChainId.ETHEREUM]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
    [ChainId.RINKEBY]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
    [ChainId.GOERLI]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
    [ChainId.BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    [ChainId.BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
    [ChainId.ETHW_MAINNET]: '0x7416072Eaf6d35e0b63b3bDAc70932CEDb65F21e',
    [ChainId.ONUS_TESTNET]: '0xb694b09C05FD8f4206e601bF7F701120702D6C7A',
    [ChainId.ONUS]: '0x785bc3471e839FB32Ec6Dc1b8A5C5f568DA9cD56',
    [ChainId.BASE_GOERLI]: '0x22E9e33Ed834a6E9AC980e62137eDa891e2498b6',
    [ChainId.OPTIMISTIC]: '0x9c12939390052919aF3155f41Bf4160Fd3666A6f',
    [ChainId.BASE_MAINNET]: '0x0A64D8858061E1C5e2BC63f2C267Cb87b47DeA57',
    [ChainId.OPBNB_MAINNET]: '',
    [ChainId.OPBNB_TESTNET]: ''

}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
    [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
    [ChainId.RINKEBY]: [WNATIVE[ChainId.GOERLI], USDC[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
    [ChainId.GOERLI]: [WNATIVE[ChainId.RINKEBY], USDC[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
    [ChainId.BSC]: [
        bscTokens.wbnb,
        bscTokens.cake,
        bscTokens.busd,
        bscTokens.usdt,
        bscTokens.btcb,
        bscTokens.eth,
        bscTokens.usdc,
    ],
    [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
    [ChainId.ETHW_MAINNET]: [ethwTokens.usdt, ethwTokens.bnb, ethwTokens.ethw],
    [ChainId.ONUS_TESTNET]: [onusTestnetTokens.usdt, onusTestnetTokens.busd, onusTestnetTokens.eth],
    [ChainId.ONUS]: [onusMainnetTokens.usdt, onusMainnetTokens.busd, onusMainnetTokens.eth],
    [ChainId.BASE_GOERLI]: [baseTestnetTokens?.usdt, baseTestnetTokens?.usdc, baseTestnetTokens?.cloud],
    [ChainId.OPTIMISTIC]: [optimismTokens?.usdt, optimismTokens?.usdc, optimismTokens?.op],
    [ChainId.BASE_MAINNET]: [baseMainnetToken.weth],
    [ChainId.OPBNB_MAINNET]: [opBNBMainnet.usdt],
    [ChainId.OPBNB_TESTNET]: [opBNBTestnet.usdt]

}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {
    // SNFTS-SFUND
    [bscTokens.snfts.address]: [bscTokens.sfund],
  },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
    [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], WBTC_ETH],
    [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
    [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
    [ChainId.BSC]: [bscTokens.busd, bscTokens.cake, bscTokens.btcb],
    [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
    [ChainId.ETHW_MAINNET]: [ethwTokens.usdt, ethwTokens.bnb, ethwTokens.ethw],
    [ChainId.ONUS_TESTNET]: [onusTestnetTokens.usdt, onusTestnetTokens.busd, onusTestnetTokens.eth],
    [ChainId.ONUS]: [onusMainnetTokens.usdt, onusMainnetTokens.busd, onusMainnetTokens.eth],
    [ChainId.BASE_GOERLI]: [baseTestnetTokens?.usdt, baseTestnetTokens?.usdc, baseTestnetTokens?.cloud],
    [ChainId.OPTIMISTIC]: [optimismTokens?.usdt, optimismTokens?.usdc, optimismTokens?.op],
    [ChainId.BASE_MAINNET]: [baseMainnetToken.weth],
    [ChainId.OPBNB_MAINNET]: [opBNBMainnet.usdt],
    [ChainId.OPBNB_TESTNET]: [opBNBTestnet.usdt]

}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
    [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
    [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
    [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
    [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt, bscTokens.cake],
    [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
    [ChainId.ETHW_MAINNET]: [ethwTokens.usdt, ethwTokens.bnb, ethwTokens.ethw],
    [ChainId.ONUS_TESTNET]: [onusTestnetTokens.usdt, onusTestnetTokens.busd, onusTestnetTokens.eth],
    [ChainId.ONUS]: [onusMainnetTokens.usdt, onusMainnetTokens.busd, onusMainnetTokens.eth],
    [ChainId.BASE_GOERLI]: [baseTestnetTokens?.usdt, baseTestnetTokens?.usdc, baseTestnetTokens?.cloud],
    [ChainId.OPTIMISTIC]: [optimismTokens?.usdt, optimismTokens?.usdc, optimismTokens?.op],
    [ChainId.BASE_MAINNET]: [WNATIVE[ChainId.BASE_MAINNET], baseMainnetToken.weth],
    [ChainId.OPBNB_MAINNET]: [opBNBMainnet.usdt],
    [ChainId.OPBNB_TESTNET]: [opBNBTestnet.usdt]

}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBTC_ETH, WNATIVE[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.cake, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'BNB'
// CAKE
export const DEFAULT_OUTPUT_CURRENCY = '0x4200000000000000000000000000000000000006'

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
