import styled from "styled-components";

export const ContainerFooter = styled.div`
    height: 110px;
`

export const ContainerContent = styled.div`
    max-width: 1320px;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1200px) {
        padding: 0px 16px;
    }
    @media screen and (max-width: 576px) {
        flex-direction:column;
        gap: 24px;
        justify-content: center;
    }
`
export const Container = styled.div`
    width: 100%;
    height: 110px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerMask = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #4B3E12 0%, rgba(20, 20, 20, 0) 50%);
    z-index: 1;
`